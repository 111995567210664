<template>
  <div class="zone">
    <div class="indent">
      <div class="left">地区验证感染趋势_省份</div>
    </div>
    <div class="conronone1">
      <div class="particulars1">
        <div class="particularsleft1">
          <div class="particularsleftone1">省份:</div>
          <div class="particularslefttwo1">12312321414</div>
        </div>
        <div class="particularscentre1">
          <div class="particularscentreone1">平台:</div>
          <div class="particularscentretwo1">箱</div>
        </div>
        <div class="particularsright1">
          <div class="particularsrightone1">时间(月):</div>
          <div class="particularsrighttwo1">2021/12/09</div>
        </div>
      </div>
    </div>

    <div class="diagram">
      <div class="diagramLift">
        <div id="main"></div>
      </div>
      <div class="diagramRight">
        <div id="mainSector"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportsDetail',

  data() {
    return {}
  },
  created() {
  },
  mounted() {
    this.echartsShow()
    this.echartsSector()
  },
  methods: {
    echartsSector() {
      var myChart1 = this.$echarts.init(document.getElementById('mainSector'))
      //配置图表
      var option = {
        title: {
          text: 'hs-CRP阴阳性占比',
          left: '0%',
          top: '2%',
          textStyle: {
            fontSize: 14,
            fontWeight: 'bold',
          },
        },
        colorStops: [
          {
            offset: 0,
            color: 'rgba(54,179,116,1)', // 0% 处的颜色
          },
          {
            offset: 1,
            color: 'rgba(54,179,116,0)', // 100% 处的颜色
          },
        ],

        color: ['#4472C5'], // 图例颜色

        legend: {
          show: true,
          type: 'plain',
          top: '4%',
          right: '5%',
          itemGap: 50, // 间隔距离
          data: ['MOT'],
        },

        grid: {
          show: true,
          left: '5%',
          right: '5%',
          top: '30%',
        },

        name: 'DATE',
        nameTextStyle: {
          //坐标轴名称的文字样式
          color: '#9AA1A9',
          fontSize: 14,
          padding: [0, 0, 0, 20],
          align: 'center',
        },

        xAxis: {
          type: 'category',
          data: [
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
          ],

          splitLine: {
            show: true,
          },
          axisLabel: {
            interval: 0,
          },
          boundaryGap: false,

          axisTick: {
            show: false,
            length: 10,
          },
          axisLabel: {
            //坐标文字
            color: '#5B6680',
          },

          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(215, 215, 215, 1)',
              width: 2,
              type: 'solid',
            },
          },
        },
        yAxis: {
          type: 'value',
          name: '用量',
          splitNumber: '3',
          axisTick: {
            show: false,
            length: 10,
          },
          axisLabel: {
            formatter: '{value}',
          },

          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(215, 215, 215, 1)',
              width: 1,
              type: 'solid',
            },
          },
        },
        tooltip: {
          trigger: 'axis', // axis   item   none三个值
        },
        series: [
          {
            name: 'CRE',
            data: [15, 23, 22, 21, 13, 14, 26, 12, 22, 33, 10],
            type: 'line',
            color: 'rgba(249, 158, 155, 0.4)',
          },
          {
            name: 'MOT',
            data: [12, 20, 15, 8, 7, 11, 13],
            type: 'line',
            lineStyle: {
              normal: {
                color: '#1EA0FE4D',
              },
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(1,189,213,.6)', // 0% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: 'rgba(1,189,213,.2)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(1,189,213,.05)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      }
      myChart1.setOption(option)
    },

    echartsShow() {
      var myChart = this.$echarts.init(document.getElementById('main'))
      //配置扇形图
      var option = {
        title: {
          text: 'hs-CRP阴阳性占比',
          textStyle: {
            fontSize: 16,
          },
        },
        textStyle: {
          color: '#00AFE9',
        },
        tooltip: {
          trigger: 'item',

          formatter: '{a} <br/>{b} : {c} ({d}%)', // <br/>换行
          //a:（系列名称） b:(数据名称) c:(数值) d:(饼图百分比)
        },

        graphic: {
          //echarts饼图中间放字
          type: 'text',
          left: 'center',
          top: 'center',
          z: 2,
          //  zlevel:10,
          style: {
            text: '1574.00',
            textAlign: 'center',
            fill: '#000',
            font: ' 22px 微软雅黑 ',
          },
        },
        series: [
          {
            name: '面积模式',
            type: 'pie',
            radius: [50, 160],
            // radius: ['30%', '70%'],
            center: ['50%', '50%'],
            roseType: 'angle',

            itemStyle: {
              borderRadius: '100%', //内圆半径和外圆半径
            },

            data: [
              {
                value: 50,
                name: '产品1',
                itemStyle: {
                  color: '#166DC9',
                },
              },
              {
                value: 40,
                name: '产品2',
                itemStyle: {
                  color: '#00AFE9',
                },
              },
              {
                value: 30,
                name: '产品3',
                itemStyle: {
                  color: '#1ACB64',
                },
              },
              {
                value: 25,
                name: '产品4',
                itemStyle: {
                  color: '#F77490',
                },
              },
              {
                value: 20,
                name: '产品5',
                itemStyle: {
                  color: '#F9C942',
                },
              },
              {
                value: 15,
                name: '产品6',
                itemStyle: {
                  color: '#2C52F1',
                },
              },
            ],
          },
          {
            name: '面积模式',
            type: 'pie',
            radius: [50, 160],
            center: ['50%', '50%'],
            roseType: 'angle',
            itemStyle: {
              borderRadius: '100%', //内圆半径和外圆半径
            },
            label: {
              show: true,
              position: 'inside',

              formatter: '{c}%',
              textStyle: {
                fontWeight: 'bold',
                fontFamily: 'Microsoft YaHei',
                color: '#FAFAFA',
                fontSize: 8,
              },
            },
            data: [
              {
                value: 50,
                name: '产品1',
                itemStyle: {
                  color: '#166DC9',
                },
              },
              {
                value: 40,
                name: '产品2',
                itemStyle: {
                  color: '#00AFE9',
                },
              },
              {
                value: 30,
                name: '产品3',
                itemStyle: {
                  color: '#1ACB64',
                },
              },
              {
                value: 25,
                name: '产品4',
                itemStyle: {
                  color: '#F77490',
                },
              },
              {
                value: 20,
                name: '产品5',
                itemStyle: {
                  color: '#F9C942',
                },
              },
              {
                value: 15,
                name: '产品6',
                itemStyle: {
                  color: '#2C52F1',
                },
              },
            ],
          },
        ],
      }

      myChart.setOption(option)
    },
  },
}
</script>

<style lang="scss" scoped>
.indent {
  height: 2.385417vw;
  background-color: transparent;
  display: flex;
  margin-bottom: 5px;
}
.left {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-size: px(20);
  font-weight: bold;
  color: #111111;
}
.conronone1 {
  background-color: #fff;
  border-radius: 5px;
  padding: 0 px(8);
}
.particulars1 {
  display: flex;
  height: px(69);
  border-bottom: 1px solid #ebf0f6;
  margin-bottom: px(20);
}
.particularsleftone1 {
  font-size: px(12);
  color: #999999;
}
.particularslefttwo1 {
  font-size: px(14);
  font-weight: bold;
  color: #333333;
}
.particularscentre1 {
  flex: 1;
  padding: px(10) 0;
  margin-left: px(15);
  border-right: 1px solid #ebf0f6;
}
.particularscentreone1 {
  font-size: px(12);
  color: #999999;
}
.particularscentretwo1 {
  font-size: px(14);
  color: #333333;
  font-weight: bold;
}
.particularsright1 {
  flex: 1;
  padding: px(10) 0;
  margin-left: px(15);
}

.particularsrightone1 {
  font-size: px(12);
  color: #999999;
}
.particularsrighttwo1 {
  font-size: px(14);
  font-weight: bold;
  color: #333333;
}
.Calendar {
  margin: 0 4px;
  width: 190px;
}
.conron {
  overflow: auto;
}
.sheet {
  border-radius: px(5);
  padding: px(5);
}
.el-table {
  font-size: px(10);
}
.has-gutter .cell {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.particularsleft1 {
  flex: 1;
  padding: px(10) 0;
  border-right: 1px solid #ebf0f6;
}
.diagram {
  height: px(837);
  display: flex;
}
.diagramLift {
  width: px(820);
  height: px(837);
  background-color: #fff;
}
.diagramRight {
  width: px(820);
  height: px(837);
  background-color: #fff;
  margin-left: px(20);
}
#main {
  width: 100%;
  height: px(650);
}
#mainSector {
  width: 100%;
  height: 100%;
}
.oneTitle {
  display: flex;
  align-items: center;
}
.radian {
  flex: 1;
  background-color: #fff;
  border-radius: 5px;
  border-top: 1px solid #ebf0f6;
}
.conronone {
  background-color: #fff;
  padding: 0 px(8);
}
.particulars {
  display: flex;
  height: px(61);
  border-bottom: 1px solid #ebf0f6;
}
.particularsleft {
  flex: 1;
  padding: px(20) 0;
  border-right: 1px solid #ebf0f6;
  display: flex;
}
.particularsleftone {
  font-size: px(14);
  color: #333333;
}
.particularslefttwo {
  font-size: px(16);
  font-weight: bold;
  color: #333333;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: px(15);
}
.particularscentre {
  flex: 1;
  padding: px(20) 0;
  margin-left: px(15);
  display: flex;
}
.particularscentreone {
  font-size: px(14);
  color: #333333;
}
.particularscentretwo {
  font-size: px(16);
  font-weight: bold;
  color: #333333;
  width: px(14);
  height: px(19);
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: px(15);
}
.bd1 {
  width: px(10);
  height: px(10);
  border-radius: 8px;
  background: #166dc9;
  margin-top: 5px;
  margin-right: 5px;
}
.bd2 {
  width: px(10);
  height: px(10);
  border-radius: 8px;
  background-color: #00afe9;
  margin-top: 5px;
  margin-right: 5px;
}
.bd3 {
  width: px(10);
  height: px(10);
  border-radius: 8px;
  background-color: #1acb64;
  margin-top: 5px;
  margin-right: 5px;
}
.bd4 {
  width: px(10);
  height: px(10);
  border-radius: 8px;
  background-color: #f77490;
  margin-top: 5px;
  margin-right: 5px;
}
.bd5 {
  width: px(10);
  height: px(10);
  border-radius: 8px;
  background-color: #f9c942;
  margin-top: 5px;
  margin-right: 5px;
}
.bd6 {
  width: px(10);
  height: px(10);
  border-radius: 8px;
  background-color: #2c52f1;
  margin-top: 5px;
  margin-right: 5px;
}
</style>
